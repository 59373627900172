'use client';
import { useEffect, useRef, useState } from 'react';
import styles from './style.module.css';

interface Props {
  list: React.ReactElement[];
}

function PaymentGrid({ list }: Props) {
  const gridRef = useRef<HTMLDivElement | null>(null);
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setAnimate(true);
          }
        });
      },
      { threshold: 0.5 }, // Trigger animation when 50% of the element is visible
    );

    if (gridRef.current) {
      observer.observe(gridRef.current);
    }

    return () => {
      if (gridRef.current) {
        observer.unobserve(gridRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={gridRef}
      className={`${styles.gridContainer} ${
        animate ? styles.animate : ''
      } min-h-[300px]`}
      onMouseEnter={() => setAnimate(true)}
      onMouseLeave={() => setAnimate(false)}
    >
      {/* row 1 */}
      <div className={styles.square}>{list[0]}</div>
      <div className={`${styles.square}  ${styles.square2} `}>{list[1]}</div>
      <div className={`${styles.square}`}>{list[2]}</div>
      {/* row 2 */}
      <div className={`${styles.square}`}>{list[3]}</div>
      <div className={`${styles.square}`}>{list[4]}</div>
      <div className={`${styles.square}`}>{list[5]}</div>
      {/* row 3 */}
      <div className={`${styles.square} ${styles.square7} `}>{list[6]}</div>
      <div className={`${styles.square}  ${styles.square8}`}>{list[7]}</div>
      <div className={`${styles.square}  ${styles.square9}`}>{list[8]}</div>
      {/* row 4 */}
      <div className={`${styles.square}  ${styles.square10}`}>{list[9]}</div>
      <div className={`${styles.square}  ${styles.square11}`}>{list[10]}</div>
    </div>
  );
}

export default PaymentGrid;
