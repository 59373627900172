'use client';
import React from 'react';
import style from './styles.module.css';
import Image from 'next/image';
import RandomExchanges from './randomExchanges';
import img40 from './assets/earth-globe.svg';

const ExchangeGlobe = () => {
  return (
    <div className={style.scene}>
      {/* ANIMATED CIRCLES */}
      <>
        <div className={style.circle} />
        <div className={style.circle} />
        <div className={style.circle} />
      </>

      {/* GLOBE LOTTIE ANIMATION */}
      <span
        className={'absolute flex h-full w-full items-center justify-center'}
      >
        <Image src={img40} alt="sdf" height={300} width={300} />
      </span>

      {/* IMAGES SECTION ANIMATION */}
      <RandomExchanges />
    </div>
  );
};

export default ExchangeGlobe;
