import React from 'react';

interface Props {
  text: string;
  bgColor?: string;
}

export default function AfiwaTag({ text, bgColor }: Props) {
  return (
    <div
      className={`${
        bgColor ? bgColor : 'bg-secondary'
      } shadow-[#5335D9]/24 inline-flex items-center justify-center rounded-full px-5 py-1 text-sm text-white shadow-lg md:text-base`}
    >
      {text}
    </div>
  );
}
