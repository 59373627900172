import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import Image from 'next/image';
import frame1 from './assets/frame1.svg';
import frame2 from './assets/frame2.svg';
import frame3 from './assets/frame3.svg';
import frame4 from './assets/frame4.svg';
import frame5 from './assets/frame5.svg';
import frame6 from './assets/frame6.svg';
import frame7 from './assets/frame7.svg';
import earthImage from './assets/afiwaEarth.png';

const RandomExchanges = () => {
  const [isImageVisible, setIsImageVisible] = useState(false); // Track when the image should be visible
  const [animationCount, setAnimationCount] = useState(3); // Track how many animations have occurred

  // Initial spans data (You can add more spans as needed)
  const initialSpans = [
    {
      component: (
        <Image key={1} src={frame1} alt="anim-icon" height={25} width={150} />
      ),
      positions: [
        { x: 145, y: 120 },
        { x: 25, y: 190 },
        { x: 10, y: 50 },
        { x: 145, y: 120 },
      ],
    },
    {
      component: (
        <Image key={2} src={frame2} alt="anim-icon" height={25} width={150} />
      ),
      positions: [
        { x: 20, y: 100 },
        { x: 190, y: 180 },
        { x: 160, y: 100 },
        { x: 20, y: 70 },
      ],
    },
    {
      component: (
        <Image key={3} src={frame3} alt="anim-icon" height={25} width={150} />
      ),
      positions: [
        { x: 25, y: 220 },
        { x: 20, y: 100 },
        { x: 25, y: 230 },
        { x: 0, y: 220 },
      ],
    },
    {
      component: (
        <Image key={4} src={frame4} alt="anim-icon" height={25} width={150} />
      ),
      positions: [
        { x: 145, y: 250 },
        { x: 100, y: 10 },
        { x: 160, y: 10 },
        { x: 140, y: 250 },
      ],
    },
    {
      component: (
        <Image key={5} src={frame5} alt="anim-icon" height={25} width={150} />
      ),
      positions: [
        { x: 100, y: 10 },
        { x: 130, y: 250 },
        { x: 0, y: 120 },
        { x: 100, y: 10 },
      ],
    },
    {
      component: (
        <Image key={6} src={frame6} alt="anim-icon" height={25} width={150} />
      ),
      positions: [
        { x: 30, y: 150 },
        { x: 180, y: 120 },
        { x: 160, y: 230 },
        { x: 40, y: 150 },
      ],
    },
    {
      component: (
        <Image key={7} src={frame7} alt="anim-icon" height={25} width={150} />
      ),
      positions: [
        { x: 160, y: 190 },
        { x: 30, y: 150 },
        { x: 150, y: 150 },
        { x: 160, y: 190 },
      ],
    },
  ];

  // Sequential animation effect with image display
  useEffect(() => {
    const interval = setInterval(() => {
      // Update animation count and position
      setAnimationCount((prevCount) => {
        let newCount = prevCount + 1;

        if (newCount % 4 === 0) {
          // Every 4rd coutn (3rd animation), show image for 3 seconds
          setIsImageVisible(true);
          setTimeout(() => {
            setIsImageVisible(false);
          }, 3000); // Display image for 3 seconds
          return 0;
        }
        return newCount;
      });
    }, 3000); // Update positions every 3 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div className={styles.exchangeScene}>
      {/* Conditionally render the image after every 3 animations */}

      <div
        className={` ${styles.displayImage}  ${
          isImageVisible ? styles.moveIn : styles.moveOut
        } `}
      >
        <Image src={earthImage} alt="img" width={300} height={300} />
      </div>

      {/* Map the spans and apply their random positions */}
      <div
        className={`${
          isImageVisible ? 'opacity-0' : 'opacity-100'
        } relative mx-auto  h-[300px] w-[300px] p-0 md:p-5`}
      >
        <div className="h-full w-full">
          {initialSpans.map((frame, index) => (
            <span
              key={index}
              style={{
                transform: `translate(${frame.positions[animationCount].x}px, ${frame.positions[animationCount].y}px)`,
              }}
            >
              {frame.component}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RandomExchanges;
