'use client';
import React from 'react';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import appRoutes from '@/constants/routes';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import AfiwaButton from '@/ui/shared/components/afiwaButton';

function ThirdSection() {
  const thirdsectionText = useTranslations('homepage.thirdsection');
  const arrowButtonText = thirdsectionText('create_account_now');

  return (
    <div className="bg-second-gradient relative min-h-[400px] overflow-hidden rounded-3xl md:min-h-[400px] lg:min-h-[500px]">
      {/* bg-image */}

      <Image
        src={'/homepage/bg-phone-demo.png'}
        alt="demo-bg-image"
        fill={true}
        className={'!top-0 !w-fit'}
        style={{ objectFit: 'contain' }}
      />

      {/* section content */}
      <div className="relative z-10 mx-5 mt-10 md:mx-20 md:mt-24">
        <div className="mb-10 flex flex-col  text-xl font-extrabold text-white md:text-4xl lg:text-4xl xl:text-5xl">
          <div>{thirdsectionText('perform_swift')}</div>
          <div>{thirdsectionText('seamles_financial')}</div>
          <div>{thirdsectionText('transactions')}</div>
        </div>

        <AfiwaButton
          text={arrowButtonText}
          href={appRoutes.signup}
          rightIcon={<ArrowRightIcon height={15} width={15} />}
        />
      </div>

      {/* image */}
      <div className="absolute -bottom-10 right-3 z-0 h-[200px] w-[300px] md:right-10 md:h-[250px] md:w-[300px] lg:h-[400px] lg:w-[500px]">
        <Image
          src={'/homepage/threephonesdemo.png'}
          alt="bg-image"
          fill={true}
          style={{ objectFit: 'contain' }}
        />
      </div>
    </div>
  );
}

export default ThirdSection;
