const httpCodes = {
  SUCCESS: 200,
  ACCES_DENIED: 403,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  CONFLICT: 409,
  UNEXPECTED: 500,
};

export default httpCodes;
