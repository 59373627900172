export const applanguages = [
  {
    name: 'French',
    abbrev: 'fr',
  },
  {
    name: 'English',
    abbrev: 'en',
  },
  {
    name: 'Русский',
    abbrev: 'ru',
  },
];
export const acceptedLocales: string[] = applanguages.map((x) => x.abbrev);
export const defaultLocale: string = 'fr';
export const localePrefix = 'always'; // Default, -> to be used to createSharedPathnamesNavigation
