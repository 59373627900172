const appRoutes = {
  // auth
  login: '/login',
  signup: '/signup',
  resetPassword: '/reset-password',
  // public
  home: '/',
  about_us: '/about-us',
  contact_us: '/contact-us',
  tutorials: '/tutorials',
  faq: '/faq',
  support: '/support',
  terms: '/terms',
  affiliate_program: '/affiliate-program',
  events: '/events',
  // authenticated
  authenticated: '/authenticated', // check auth session and redirects uses to their dashoboard
  // users
  users: '/users',
  users_profile: '/users/profile',
  users_exchange: '/users/exchange',
  users_transactions: '/users/transactions',
  users_history: '/users/history',
  users_affiliates: '/users/affiliates',
  users_events: '/users/events',
  // admin-routes
  admin: '/admin',
  admin_dashboard: '/admin',
  admin_manage_transactions: '/admin/transactions',
  admin_manage_assets: '/admin/assets',
  admin_profile: '/admin/profile',
  admin_stats: '/admin/stats',
  admin_managers: '/admin/managers',
  admin_manage_customers: '/admin/customers',
  admin_manage_affiliates: '/admin/affiliates',
};
export default appRoutes;
