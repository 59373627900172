import(/* webpackMode: "eager", webpackExports: ["Button"] */ "__barrel_optimize__?names=Button!=!/vercel/path0/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "__barrel_optimize__?names=Carousel!=!/vercel/path0/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/react-fast-marquee/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/ui/pages/home/customersTestimonies/assets/customer1.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/ui/pages/home/customersTestimonies/assets/customer2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/ui/pages/home/customersTestimonies/assets/customer3.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/ui/pages/home/customersTestimonies/assets/customer4.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/ui/pages/home/customerSupport/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/ui/pages/home/exchangeSection/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/ui/pages/home/joinAffiliateProgram/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/ui/pages/home/thirdSection/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/ui/pages/home/transactInEasySteps/EasyStepsTransact.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/ui/shared/components/animations/exchangeGlobe/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/ui/shared/components/animations/heroGlobe/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/ui/shared/components/animations/paymentGrid/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/ui/shared/components/trustRating/index.tsx");
