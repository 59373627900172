import React from 'react';
import { Button, ButtonProps } from 'antd';
import { Link } from '@/intl/navigation';

interface Props extends ButtonProps {
  text: string;
  href?: string;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  target?: React.HTMLAttributeAnchorTarget | undefined;
  loading?: boolean;
  wrapperClass?: string;
}

export default function AfiwaButton(props: Props) {
  // props
  const {
    href, // for link buttons
    text,
    leftIcon,
    rightIcon,
    className,
    wrapperClass = '',
    size = 'large',
    target = '',
    loading,
    ...rest
  } = props;

  // case : normal ant-d button
  // augmenred right-icons prop
  if (!href) {
    return (
      <Button
        {...rest}
        size={size}
        icon={leftIcon}
        loading={loading}
        className={`afiwa-button !inline-flex items-center justify-center ${
          className || ''
        }   ${!rest.type && '!text-primary'}`}
      >
        <span className="font-bold leading-none">{text}</span>
        {rightIcon && <span className="ml-2">{rightIcon}</span>}
      </Button>
    );
  }

  // case : link button
  /* Display intl-link over button, in order to mimic link behavoir. */
  /* This enables use of intl-links while using ant-d button style   */
  return (
    <div className={`relative inline-block w-fit ${wrapperClass}`}>
      <Button
        {...rest}
        size={size}
        icon={leftIcon}
        className={`afiwa-button !inline-flex items-center justify-center ${
          className || ''
        } ${!rest.type && 'font-bold !text-primary'} m-0`}
      >
        <span className="font-bold leading-none">{text} </span>
        {rightIcon && <span className="ml-2">{rightIcon}</span>}
      </Button>
      <Link
        href={href}
        target={target}
        className="absolute left-0 h-full w-full"
      >
        <span className="hidden">{text}</span>
      </Link>
    </div>
  );
}
