'use client';
import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './styles.module.css';
import Image from 'next/image';
import img1 from './assets/slide1.png';
import img2 from './assets/slide2.png';
import img3 from './assets/slide3.png';
import img4 from './assets/slide4.png';
import img5 from './assets/slide5.png';
import img6 from './assets/slide6.png';
import img7 from './assets/slide7.png';
import afiwaLogo from './assets/afiwa_logo.png';
const tickLogo = require('./assets/tick-circle.png');
import { useTranslations } from 'next-intl';
import { StaticImageData } from 'next/image';

interface Notification {
  title?: string;
  description: string;
  position: { x: number; y: number };
}

interface Action {
  description: string;
  position: { x: number; y: number };
}

interface Slide {
  id: number;
  backgroundColor: string;
  image: StaticImageData | string;
  country: StaticImageData | string;
  notification: Notification;
  action?: Action;
}

export default function SliderAnimation() {
  const [activeSlide, setActiveSlide] = useState(0);
  const text = useTranslations('homepage.join_affiliate_program.testimonies');

  // Slides array with the new notification and action data
  const slides: Slide[] = [
    {
      id: 0,
      backgroundColor: '#ff6f61',
      image: img1,
      country: tickLogo,
      notification: {
        title: text('title1'),
        description: text('alert1'),
        position: { x: 0, y: 250 },
      },
      action: {
        description: text('thankYou'),
        position: { x: 12, y: 345 },
      },
    },
    {
      id: 1,
      backgroundColor: '#6fa3ff',
      image: img2,
      country: '/country/mali.png',
      notification: {
        title: 'Account Update',
        description: text('alert2'),
        position: { x: 0, y: 120 },
      },
      action: {
        description: text('thankYou'),
        position: { x: 0, y: 225 },
      },
    },
    {
      id: 2,
      backgroundColor: '#ffe66f',
      image: img3,
      country: '/country/nigeria.png',
      notification: {
        title: '',
        description: text('alert3'),
        position: { x: 150, y: 292 },
      },
    },
    {
      id: 3,
      backgroundColor: '#61ff6f',
      image: img4,
      country: '/country/russia.png',
      notification: {
        title: '',
        description: text('alert4'),
        position: { x: 160, y: 320 },
      },
      action: {
        description: text('thankYou'),
        position: { x: 160, y: 280 },
      },
    },
    {
      id: 4,
      backgroundColor: '#6f61ff',
      image: img5,
      country: '/country/nigeria.png',
      notification: {
        title: '',
        description: text('alert5'),
        position: { x: 10, y: 40 },
      },
      action: {
        description: text('referral'),
        position: { x: 202, y: 290 },
      },
    },
    {
      id: 5,
      backgroundColor: '#ff61e6',
      image: img6,
      country: '/country/ivory-coast.png',
      notification: {
        title: text('title1'),
        description: text('alert6'),
        position: { x: 62, y: 295 },
      },
      action: {
        description: text('theBest'),
        position: { x: 132, y: 255 },
      },
    },
    {
      id: 6,
      backgroundColor: '#e6ff61',
      image: img7,
      country: '/country/rdc.png',
      notification: {
        title: text('title1'),
        description: text('alert2'),
        position: { x: 150, y: 25 },
      },
      action: {
        description: 'Afiwa Exchange !!!',
        position: { x: 62, y: 295 },
      },
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prev) => (prev + 1) % slides.length);
    }, 4000); // change slide every 4 seconds

    return () => clearInterval(interval); // cleanup interval on unmount
  }, [slides.length]);

  return (
    <div className={styles.container}>
      {/* First floating text */}
      <motion.span
        className={styles.floatingText}
        animate={{
          x: slides[activeSlide].notification.position.x,
          y: slides[activeSlide].notification.position.y,
        }}
        transition={{ duration: 1 }}
      >
        <AnimeNotification
          title={slides[activeSlide]?.notification.title}
          description={slides[activeSlide]?.notification.description}
        />
      </motion.span>

      {/* Second floating text */}
      {slides.length > 0 && slides[activeSlide]?.action && (
        <motion.span
          className={styles.floatingText}
          animate={{
            x: slides[activeSlide]?.action?.position.x ?? 0, // default to 0 if undefined
            y: slides[activeSlide]?.action?.position.y ?? 0, // default to 0 if undefined
          }}
          transition={{ duration: 1 }}
        >
          <AnimeNotification2
            imageUrl={slides[activeSlide]?.country}
            description={slides[activeSlide]?.action?.description || ''}
          />
        </motion.span>
      )}

      {slides.length > 0 && (
        <AnimatePresence>
          {slides.map((slide, index) =>
            activeSlide === index ? (
              <motion.div
                key={slide.id}
                initial={{ opacity: 0 }} // Start invisible
                animate={{ opacity: 1 }} // Fade in active slide
                exit={{ opacity: 0 }} // Fade out old slide
                transition={{ duration: 1 }} // Duration of fade
              >
                <div className="relative mx-auto h-[400px] max-w-[350px]">
                  <Image
                    src={slide.image}
                    alt="Slide image"
                    fill={true}
                    style={{ objectFit: 'contain' }}
                    className="absolute"
                  />
                </div>
              </motion.div>
            ) : null,
          )}
        </AnimatePresence>
      )}
    </div>
  );
}

function AnimeNotification2({
  description,
  imageUrl,
}: {
  imageUrl: StaticImageData | string;
  description: string;
}) {
  return (
    <div className="flex max-w-[180px] items-center gap-1 rounded-lg rounded-tl-none bg-[#F4F5F8] p-1 text-[10px]">
      <Image src={imageUrl} alt="Country flag" height={20} width={20} />
      <span className="font-normal text-gray-500">{description}</span>
    </div>
  );
}

function AnimeNotification({
  title,
  description,
}: {
  title?: string;
  description: string;
}) {
  const text = useTranslations('homepage.join_affiliate_program');
  return (
    <div className="max-w-[180px] rounded-lg bg-white/90 p-1 text-[10px]">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Image src={afiwaLogo} alt="Afiwa logo" height={20} width={20} />
          <span className="ms-1 text-[12px]">Afiwa Exchange</span>
        </div>
        <span className="text-[8px] text-gray-300">{text('minutes_ago')}</span>
      </div>
      <div className="mt-1 ps-5 font-normal leading-tight text-gray-400">
        {title && <span className="block font-bold">{title}</span>}
        <span className="line-clamp-3 text-[9px] leading-tight">
          {description}
        </span>
      </div>
    </div>
  );
}
