import React from 'react';
import Image from 'next/image';
import _ from 'underscore';

type Props = { imageUrl: string; text: string; disabled?: boolean };

function SelectOption({ imageUrl, text, disabled = false }: Props) {
  return (
    <div className="flex items-center">
      <div className="me-2 h-[25px] w-[25px]">
        <Image
          src={imageUrl}
          height={40}
          width={40}
          alt="country-img"
          className={`rounded-full ${disabled && 'opacity-60'}`}
        />
      </div>
      <span
        className={`mr-2 text-sm font-semibold ${
          disabled ? 'text-gray-200' : 'text-primary-dark'
        }`}
      >
        {text}
      </span>
    </div>
  );
}

export default SelectOption;
