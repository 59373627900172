'use client';
import React from 'react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

interface Props {
  rating?: number;
  className?: string;
}

export default function TrustRating({ rating = 5, className = '' }: Props) {
  const homepageText = useTranslations('homepage');
  const stars = Array(rating).fill(1);

  return (
    <div className={`text-left text-base ${className}`}>
      <div className="inline-flex gap-3 ">
        <div className="flex">
          {stars.map((x, key) => (
            <Image
              key={key}
              src={'/socialMedia/star.svg'}
              alt="rating-icon"
              height={25}
              width={25}
            />
          ))}
        </div>
        <div className="flex items-center justify-center gap-x-1">
          <Image
            src={'/socialMedia/telegram.svg'}
            alt="telegram-icon"
            height={27}
            width={27}
          />
          <div className="text-lg font-bold">Telegram</div>
        </div>
      </div>
      <p className="font-light">{homepageText('rated_as_trustworthy')}</p>
    </div>
  );
}
