'use client';
import React, { useState, useEffect } from 'react';
import Image, { StaticImageData } from 'next/image';
import { useTranslations } from 'next-intl';
import { motion } from 'framer-motion';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';
import { Button } from 'antd';
import step1 from './assets/step1.png';
import step2 from './assets/step2.png';
import step3 from './assets/step3.gif';
import step4 from './assets/step4.png';

interface TransactStepProps {
  index: string;
  title: string;
  description: string;
  imagePath: StaticImageData;
}

function EasyStepsTransact() {
  // states
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  // texts
  const easyStepsText = useTranslations('homepage.easy_steps_transact');

  // frames
  const transactSteps: TransactStepProps[] = [
    {
      index: '01',
      title: easyStepsText('title1'),
      description: easyStepsText('description1'),
      imagePath: step1,
    },
    {
      index: '02',
      title: easyStepsText('title2'),
      description: easyStepsText('description2'),
      imagePath: step2,
    },
    {
      index: '03',
      title: easyStepsText('title3'),
      description: easyStepsText('description3'),
      imagePath: step3,
    },
    {
      index: '04',
      title: easyStepsText('title4'),
      description: easyStepsText('description4'),
      imagePath: step4,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % transactSteps.length);
    }, 5000); // Update every 5 seconds

    return () => clearInterval(interval);
  }, [transactSteps.length]);

  // navigation methods
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? transactSteps.length - 1 : prevIndex - 1,
    );
  };
  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % transactSteps.length);
  };

  return (
    <div className=" relative flex flex-col-reverse items-center justify-center md:flex-row">
      <div className="flex flex-1 justify-center">
        <div className="w-full md:ml-20 md:max-w-[350px] lg:ml-36">
          <motion.div
            key={transactSteps[currentIndex].title}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <div className="space-y-5">
              {/* slide-number */}
              <div className="text-5xl  font-extrabold text-primary">
                {transactSteps[currentIndex].index}
              </div>
              {/* slide title */}
              <div className="font-bold text-[#03438C]">
                {transactSteps[currentIndex].title}
              </div>
              {/* slide description */}
              <div className="mt-3 font-light ">
                {transactSteps[currentIndex].description}
              </div>
            </div>
          </motion.div>
          <div className="relative mt-3 hidden items-center justify-between gap-5 md:mt-10 md:flex  md:justify-start">
            <Button
              type="primary"
              disabled={currentIndex == 0}
              icon={<ArrowLeftIcon height={25} width={25} />}
              onClick={handlePrevClick}
            />
            <Button
              type="primary"
              disabled={currentIndex == 3}
              icon={<ArrowRightIcon height={25} width={25} />}
              onClick={handleNextClick}
            />
          </div>
        </div>
      </div>

      <div className="relative mb-14 flex-1 md:mb-0">
        <motion.div
          // key={transactSteps[currentIndex].imagePath}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 2 }}
        >
          <div className="relative flex h-[420px] w-[350px] items-center justify-center md:w-[650px]">
            {/* rounded bg (blue colors) */}
            <div className="drop-shadow-primary absolute h-[300px] w-[300px] rounded-full  bg-blue-300 drop-shadow-xl"></div>
            {/* slide image */}
            <Image
              alt="demo-image"
              src={transactSteps[currentIndex].imagePath}
              fill={true}
              style={{ objectFit: 'contain' }}
            />
          </div>
        </motion.div>
        {/* buttons (mobile only) */}
        <div className="relative z-10 -mt-16 flex items-center justify-between gap-5 md:mt-10 md:hidden md:justify-start">
          <Button
            type="primary"
            disabled={currentIndex == 0}
            className="!rounded-full !bg-white !text-primary"
            icon={<ArrowLeftIcon height={15} width={15} />}
            onClick={handlePrevClick}
          />
          <Button
            type="primary"
            disabled={currentIndex == 3}
            className="!rounded-full !bg-white !text-primary"
            icon={<ArrowRightIcon height={15} width={15} />}
            onClick={handleNextClick}
          />
        </div>
      </div>
    </div>
  );
}

export default EasyStepsTransact;
