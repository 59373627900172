'use client';
import React from 'react';
import { Select, Input, Form } from 'antd';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import httpCodes from '@/constants/httpCodes';
import _ from 'underscore';
import AfiwaButton from '@/ui/shared/components/afiwaButton';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { sanitizeInput } from '@/utils/acceptOnlyDigit';
import BigNumber from 'bignumber.js';
import appRoutes from '@/constants/routes';
import SelectOption from '@/ui/shared/components/selectOption';
import { Trado, Payo } from './model/types';

const ExchangeForm = () => {
  /* states */
  const [tradeSpaces, setTradeSpaces] = React.useState<Trado[]>([]);
  const [sendMethods, setSendMethods] = React.useState<Payo[] | []>([]);
  const [receiveMethods, setReceiveMethods] = React.useState<Payo[] | []>([]);
  // traded-pairs
  const [fromSpace, setFromSpace] = React.useState<number>(0);
  const [toSpace, setToSpace] = React.useState<number>(0);
  const [sendMethod, setSendMethod] = React.useState<number>(0);
  const [receiveMethod, setReceiveMethod] = React.useState<number>(0);
  // trade-amt
  const [sendAmount, setSendAmount] = React.useState('');
  const [receiveAmount, setReceiveAmount] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  // START--- for-swap (DO NOT EDIT  WITHOUT UNDERSTANDING IT"S ROLE)
  // avoids the manual validation from running at first render
  const [isSwapped, setIsSwapped] = React.useState<boolean | undefined>(
    undefined,
  );

  /* HOOKS */
  const [form1] = Form.useForm();

  /* EFFECTS */

  // MONEY CALC EFFECT
  React.useEffect(() => {
    if (sendAmount) {
      calcReceiveAmount(sendAmount);
    }
  }, [sendMethod, receiveMethod, fromSpace, toSpace]);

  // PREFILLING EFFECT
  React.useEffect(() => {
    _getTradeSpacesAndPrefillSelects();
  }, []);

  /* METHODS */

  // on-space-change
  const onSpaceFromChange = (value: number) => {
    const methods = tradeSpaces.find((space) => space.id === value)
      ?.paymentAccounts;
    setFromSpace(value);

    if (methods) {
      setSendMethods(methods);
      setSendMethod(methods[0].paymentMethodId);
    }
  };

  // on-space-change
  const onSpaceToChange = (value: number) => {
    const methods = tradeSpaces.find((space) => space.id === value)
      ?.paymentAccounts;
    setToSpace(value);

    if (methods) {
      setReceiveMethods(methods);
      setReceiveMethod(methods[0].paymentMethodId);
    }
  };

  // on-amount-change
  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sendAmt = e.target.value;

    // check non zero prefill
    if (_.isNull(sendAmt) || sendAmt == '0') {
      setReceiveAmount('0');
    } else {
      setSendAmount(sendAmt); // state update
      calcReceiveAmount(sendAmt); // form-field-update
    }
  };

  // on-amount-change
  const onReceiveAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const receiveAmt = e.target.value;

    // check non zero prefill
    if (_.isNull(receiveAmt) || receiveAmt == '0') {
      setSendAmount('0');
    } else {
      setReceiveAmount(receiveAmt); // state update
      calcSendAmount(receiveAmt); // form-field-update
    }
  };

  // get trade rate
  const getRate = (methods: Payo[], index: number, type: 'SELL' | 'BUY') => {
    if (getMethodById(methods, index)) {
      const m = getMethodById(methods, index);
      // return buy-rate (send)
      if (m && type == 'BUY') {
        return `1$ = ${m.buyRate}`;
      }
      // return sell-rate (receive)
      if (m && type == 'SELL') {
        return `1$ = ${m.sellRate}`;
      }
    }
    return null;
  };

  // get trade currency
  const getCurrency = (spaces: Trado[], index: number): string => {
    if (spaces[0]) {
      const space = spaces.find((s) => s.id === index);
      if (space) return space.currencySymbol;
    }
    return '';
  };

  // calc traded amount based on rate
  const calcReceiveAmount = (amount: string) => {
    // calc receieve amt
    if (amount) {
      const sendAmt = new BigNumber(amount);
      // get chosen exchange methods
      const sendM = getMethodById(sendMethods, sendMethod);
      const receiveM = getMethodById(receiveMethods, receiveMethod);
      // calc receive-amt

      if (sendM && receiveM) {
        // get the exchange buying-rates and selling-rates
        const sendRate = new BigNumber(sendM.buyRate);
        const receiveRate = new BigNumber(receiveM.sellRate);
        // calc exchange rate
        let result = sendAmt.dividedBy(sendRate).multipliedBy(receiveRate);

        if (receiveM.paymentMethodType == 'CRYPTO') {
          setReceiveAmount(result.toFixed(5).toString());
          form1.setFieldValue('receiveAmount', result.toFixed(5).toString());
        } else {
          setReceiveAmount(result.toFixed(0).toString());
          form1.setFieldValue('receiveAmount', result.toFixed(0).toString());
        }
      } else {
        setReceiveAmount('0');
      }
    } else {
      setReceiveAmount('0');
    }
  };

  // calc traded amount based on rate
  const calcSendAmount = (amount: string) => {
    // calc receieve amt
    if (amount) {
      const inputAmt = new BigNumber(amount);
      // get chosen exchange methods
      const sendM = getMethodById(sendMethods, sendMethod);
      const receiveM = getMethodById(receiveMethods, receiveMethod);
      // calc send-amt

      if (sendM && receiveM) {
        // get the exchange buying-rates and selling-rates
        const sendRate = new BigNumber(sendM.buyRate);
        const receiveRate = new BigNumber(receiveM.sellRate);
        // calc exchange rate
        let result = inputAmt.dividedBy(receiveRate).multipliedBy(sendRate);
        if (sendM.paymentMethodType == 'CRYPTO') {
          setSendAmount(result.toFixed(5).toString());
          form1.setFieldValue('sendAmount', result.toFixed(5).toString());
        } else {
          setSendAmount(result.toFixed(0).toString());
          form1.setFieldValue('sendAmount', result.toFixed(0).toString());
        }
      } else {
        setSendAmount('0');
      }
    } else {
      setSendAmount('0');
    }
  };

  // return selected method
  const getMethodById = (methods: Payo[], id: number) => {
    if (methods[0]) {
      const method = methods.find((m) => m.paymentMethodId === id);
      if (method) return method;
    }
    return null;
  };

  // api-call, get trade spaces and prefill selects
  async function _getTradeSpacesAndPrefillSelects() {
    // load
    setLoading(true);
    const response = await fetch('/api/trade-space/all', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .catch((err) => {})
      .finally(() => setLoading(false));

    if (response.status == httpCodes.SUCCESS && !_.isEmpty(response.data)) {
      // set trade spaces
      const spaces: Trado[] = response.data;
      // prefill form
      if (spaces.length > 0) {
        // set state with all spaces
        setTradeSpaces(spaces);
        //******** initialize lists for the spaces html-selects ***************/
        // choose 2 non crypto-spaces to prefill the form
        const nonCryptoSpaces = spaces.filter((x) => x.tradeType !== 'CRYPTO');
        const initSpace1 = nonCryptoSpaces[0];
        const initSpace2 = nonCryptoSpaces[1];
        // init lists for the spaces html-selects
        setFromSpace(initSpace1.id);
        setToSpace(initSpace2.id);
        // get transaction-methods-list from trade-spaces
        const initSendMethods = initSpace1.paymentAccounts;
        const initReceiveMethods = initSpace2.paymentAccounts;
        // initialize transaction methods html-selects
        setSendMethods(initSendMethods);
        setReceiveMethods(initReceiveMethods);
        // initialize transaction methods default values
        setSendMethod(initSendMethods[0].paymentMethodId);
        setReceiveMethod(initReceiveMethods[0].paymentMethodId);
      }
    }
  }

  // Create an async wrapper for setState
  function swapForm() {
    // swap space select values
    const sendSpaceTemp = fromSpace;
    setFromSpace(toSpace);
    setToSpace(sendSpaceTemp);
    // swap exchange method lists
    const methodsTemp = sendMethods;
    setSendMethods(receiveMethods);
    setReceiveMethods(methodsTemp);
    // swap exhange method
    const sendMethodTemp = sendMethod;
    setSendMethod(receiveMethod);
    setReceiveMethod(sendMethodTemp);
    //
    setIsSwapped(!isSwapped);
  }

  // text-messages
  const text2 = useTranslations('inApp');
  const text = useTranslations('homepage.exchange');

  return (
    <>
      {/* form */}
      <Form form={form1} name="tradeInfo" autoComplete="off">
        <div className="flex flex-col gap-4 md:flex-row md:gap-3">
          {/* exchange-part-1 */}
          <div className="flex flex-col gap-3">
            {/* space-select */}
            <div className="rounded-lg border bg-white p-[14px]">
              <label htmlFor="s1" className="mb-3 block text-primary-pale">
                {text('you_send')}
              </label>
              <Select
                id="s1"
                size="large"
                value={fromSpace}
                loading={loading} // loading data
                placeholder={'country'}
                onChange={onSpaceFromChange}
                className="custom-ant-select w-full"
                options={tradeSpaces
                  // Filter out the invalid crypto-to-crypto combinations
                  .filter((space) => {
                    const selectedToSpace = tradeSpaces.find(
                      (x) => x.id == toSpace,
                    );
                    // Check if it's a crypto-to-crypto situation
                    if (selectedToSpace) {
                      const isCryptToCrypto =
                        selectedToSpace.tradeType === 'CRYPTO' &&
                        space.tradeType === 'CRYPTO';
                      return !isCryptToCrypto; // Keep valid options, filter out invalid ones
                    }
                    return true; // If no selectedToSpace, return all options
                  })
                  .map((space) => ({
                    value: space.id,
                    label: (
                      <SelectOption
                        text={space.name}
                        imageUrl={space.imageUrl}
                      />
                    ),
                  }))}
              />
            </div>
            {/* currency-select */}
            <div className="rounded-lg border bg-white p-[14px]">
              {/* label */}
              <label htmlFor="md1" className="mb-3 block text-primary-pale">
                {text('select_channel')}
              </label>
              {/* md1-select */}
              <div className="flex items-center gap-3">
                <Select
                  id={'md1'}
                  size="large"
                  placeholder={'transaction'}
                  disabled={receiveMethods.length < 1}
                  className="custom-ant-select w-[150px]"
                  value={sendMethod}
                  onChange={(value) => {
                    setSendMethod(value);
                  }}
                  options={sendMethods.map((m) => ({
                    value: m.paymentMethodId,
                    label: (
                      <SelectOption
                        text={m.paymentMethodSymbol}
                        imageUrl={m.paymentMethodImageUrl}
                      />
                    ),
                  }))}
                />
                <Form.Item name={'sendAmount'} className="!mb-0 w-[150px]">
                  <Input
                    size={'large'}
                    placeholder={'0'}
                    value={sendAmount}
                    className={'afiwa-input-number text-right'}
                    onChange={onAmountChange}
                    onInput={(e) =>
                      (e.currentTarget.value = sanitizeInput(
                        e.currentTarget.value,
                        sendAmount,
                      ))
                    }
                  />
                </Form.Item>
              </div>
            </div>
            {/* conversion-rate */}
            <div className="text-left md:text-center">
              <span className="me-3 inline-block">{`${text2('rate')} :`}</span>
              <span className="font-bold text-[#35A100]">
                {getRate(sendMethods, sendMethod, 'BUY')}
                <span className="ms-1">
                  {getCurrency(tradeSpaces, fromSpace)}
                </span>
              </span>
            </div>
          </div>
          {/* swap-exchange */}
          <div className="flex items-center justify-center">
            <Image
              src={`/icons/exchange-icon.svg`}
              alt="exchange-icon"
              height={38}
              width={38}
              className="cursor-pointer"
              onClick={() => swapForm()}
            />
          </div>
          {/* exchange-part-2 */}
          <div className="flex flex-col gap-3">
            {/* space-select */}
            <div className="rounded-lg border bg-white p-[14px]">
              <label htmlFor="s2" className="mb-3 block text-primary-pale">
                {text('receiver_will_get')}
              </label>
              <Select
                id="s2"
                size="large"
                value={toSpace}
                loading={loading} // loading data
                placeholder={'country'}
                onChange={onSpaceToChange}
                className="custom-ant-select w-full"
                options={tradeSpaces
                  // Filter out the invalid crypto-to-crypto combinations
                  .filter((space) => {
                    const selectedFromSpace = tradeSpaces.find(
                      (x) => x.id == fromSpace,
                    );
                    // Check if it's a crypto-to-crypto situation
                    if (selectedFromSpace) {
                      const isCryptToCrypto =
                        selectedFromSpace.tradeType === 'CRYPTO' &&
                        space.tradeType === 'CRYPTO';
                      return !isCryptToCrypto; // Keep valid options, filter out invalid ones
                    }
                    return true; // If no selectedFromSpace, return all options
                  })
                  .map((space) => ({
                    value: space.id,
                    label: (
                      <SelectOption
                        text={space.name}
                        imageUrl={space.imageUrl}
                      />
                    ),
                  }))}
              />
            </div>
            {/* currency-select */}
            <div className="flex flex-col gap-3">
              <div className="rounded-lg border bg-white p-[14px]">
                {/* label */}
                <label htmlFor="md1" className="mb-3 block text-primary-pale">
                  {text('to')}
                </label>
                {/* md1-select */}
                <div className="flex items-center gap-3">
                  <Select
                    id={'md2'}
                    size="large"
                    placeholder={'Receive Methods'}
                    disabled={receiveMethods.length < 1}
                    className="custom-ant-select w-[150px]"
                    value={receiveMethod}
                    onChange={(value) => {
                      setReceiveMethod(value);
                    }}
                    options={receiveMethods.map((m) => ({
                      value: m.paymentMethodId,
                      label: (
                        <SelectOption
                          text={m.paymentMethodSymbol}
                          imageUrl={m.paymentMethodImageUrl}
                        />
                      ),
                    }))}
                  />
                  <Form.Item name={'receiveAmount'} className="!mb-0 w-[150px]">
                    <Input
                      size={'large'}
                      placeholder={'0'}
                      value={receiveAmount}
                      className={'afiwa-input-number text-right'}
                      onChange={onReceiveAmountChange}
                      onInput={(e) =>
                        (e.currentTarget.value = sanitizeInput(
                          e.currentTarget.value,
                          receiveAmount,
                        ))
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="text-left md:text-center">
                <span className="me-3 inline-block capitalize">{`${text2(
                  'rate',
                )} :`}</span>
                <span className="font-bold text-[#35A100]">
                  {getRate(receiveMethods, receiveMethod, 'SELL')}
                  <span className="ms-1">
                    {getCurrency(tradeSpaces, toSpace)}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* submit button */}
        <div className="mx-auto mt-10 text-center">
          <AfiwaButton
            type="primary"
            size="large"
            htmlType="submit"
            className="!min-w-[250px]"
            rightIcon={<ArrowRightIcon height={15} width={15} />}
            text={text2('next')}
            href={appRoutes.login}
          />
        </div>
      </Form>
    </>
  );
};

export default ExchangeForm;
