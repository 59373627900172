import BigNumber from 'bignumber.js';

export default function acceptOnlyDigit(s: string) {
  if (/^\d*\.?\d*$/.test(s)) {
    return s;
  }
  return '';
}

export function sanitizeInput(s: string, amt: string) {
  const bignumb = BigNumber(amt);
  if (bignumb.isGreaterThan(10000000)) {
    return '';
  }
  if (/^\d*\.?\d*$/.test(s)) {
    return s;
  }
  return '';
}
