'use client';
import React from 'react';
import style from './globe1.module.css';
import Lottie from 'react-lottie';
import img1 from './assets/slide1.svg';
import img2 from './assets/slide2.svg';
import img3 from './assets/slide3.svg';
import img4 from './assets/slide4.svg';
import animationData from './assets/globle-lottie.json';

import Image from 'next/image';
import { useTranslations } from 'next-intl';

const HeroGlobe = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  const text = useTranslations('homepage');

  return (
    <div className={style.scene}>
      {/* GLOBE LOTTIE ANIMATION */}
      <div className={style.globeLottieWrapper}>
        <Lottie
          options={defaultOptions}
          style={{ width: 'inherit', height: 'inherit' }}
        />
      </div>

      {/* ANIMATED CIRCLES */}
      <div className={style.animatedCircles}>
        <div className={style.circle} />
        <div className={style.circle} />
        <div className={style.circle} />
      </div>

      {/* IMAGES SECTION ANIMATION */}
      <div className={`${style.slideContainer}`}>
        {/* <span className="slide">
          <Image src={img1} alt="sdf" height={500} width={500} />
        </span> */}
        <span className={style.slide}>
          <Image src={img2} alt="sdf" className={style.slideImage} />
        </span>
        <span className={style.slide}>
          <Image src={img3} alt="sdf" className={style.slideImage} />
          <p className="absolute inset-0 flex items-center justify-center ">
            <span
              className="w-[160px] text-center text-white"
              style={{ textShadow: '2px 2px 15px rgba(0, 0, 0, 1)' }}
            >
              {text('speed_and_reliability')}
            </span>
          </p>
        </span>
        <span className={style.slide}>
          <Image src={img4} alt="sdf" className={style.slideImage} />
          <p className="absolute inset-0 flex items-center justify-center ">
            <span
              className="w-[160px] text-center text-white"
              style={{ textShadow: '2px 2px 15px rgba(0, 0, 0, 1)' }}
            >
              {text('userfriendly_experience')}
            </span>
          </p>
        </span>
      </div>
    </div>
  );
};

export default HeroGlobe;
