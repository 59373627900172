'use client';
import React from 'react';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { motion } from 'framer-motion';
import AfiwaTag from '@/ui/shared/components/afiwaTag';
import SectionHeading from '@/ui/shared/components/sectionHeading';
import AfiwaButton from '@/ui/shared/components/afiwaButton';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { afiwaSocials } from '@/constants/contacts';

function CustomerSupport() {
  const heroText = useTranslations('homepage.customer_support');

  // Animation variants
  const slideInFromLeft = {
    hidden: { opacity: 0, x: -100 }, // Initially off-screen to the left
    visible: { opacity: 1, x: 0 }, // Moves into view
  };

  const slideInFromRight = {
    hidden: { opacity: 0, x: 100 }, // Initially off-screen to the right
    visible: { opacity: 1, x: 0 }, // Moves into view
  };

  return (
    <div>
      <div className="flex flex-col-reverse items-center overflow-hidden md:flex-row">
        {/* Image Block - Sliding in from the left */}
        <motion.div
          className="mt-10 flex flex-1 md:mt-0"
          initial="hidden"
          whileInView="visible" // Animation triggers when in view
          viewport={{ once: true, amount: 0.5 }} // Only trigger once, when 50% of the element is in view
          variants={slideInFromLeft}
          transition={{ duration: 0.8 }} // Customize the animation duration
        >
          <div className="relative flex h-[300px] w-[350px] items-center justify-center sm:w-[400px] lg:h-[500px] lg:w-[600px]">
            <Image
              src={'/homepage/support.png'}
              alt="affiliate-img"
              className="absolute lg:-left-20"
              width={944}
              height={923}
            />
          </div>
        </motion.div>

        {/* Text Content Block - Sliding in from the right */}
        <motion.div
          className="mb-3 flex-1 text-center md:mb-0 md:text-left"
          initial="hidden"
          whileInView="visible" // Animation triggers when in view
          viewport={{ once: true, amount: 0.5 }} // Only trigger once, when 50% of the element is in view
          variants={slideInFromRight}
          transition={{ duration: 0.8 }} // Customize the animation duration
        >
          <AfiwaTag text={heroText('support')} />
          <SectionHeading className="my-10 space-x-1">
            <span className=" text-primary-dark">{heroText('dedicated')}</span>
            <span className=" inline-block text-primary">
              {heroText('customer')}
            </span>
            <br />
            <span className="text-primary"> {heroText('help_desk')}</span>
          </SectionHeading>
          <p className="w-full font-light">
            {`${heroText('customer_support_description')} `}
          </p>
          <AfiwaButton
            type="primary"
            text={heroText('client_support')}
            wrapperClass="mt-10"
            rightIcon={<ArrowRightIcon height={15} width={15} />}
            href={afiwaSocials.whatsapp.link}
          />
        </motion.div>
      </div>
    </div>
  );
}

export default CustomerSupport;
