'use client';
import React from 'react';
import { useTranslations } from 'next-intl';
import PublicExchangeForm from '@/ui/features/exchange/publicExchangeForm';
import SectionHeading from '@/ui/shared/components/sectionHeading';

export default function ExchangeSection() {
  const exchangeText = useTranslations('homepage.exchange');

  return (
    <>
      {/* heading */}
      <SectionHeading className="text-center">
        <span className="mr-1 text-primary-dark">
          {exchangeText('swift_online')}
        </span>
        <span className="text-primary">{exchangeText('money_transfer')}</span>
      </SectionHeading>
      {/* description*/}
      <p className="text-light mt-5 text-center md:text-xl">
        {exchangeText('enabling_you_to_make')}
      </p>
      {/* exchange */}
      <div className="mx-0 mt-10 w-full gap-3 md:mx-auto md:mt-16 md:w-fit">
        {/* simiulation form */}
        <PublicExchangeForm />
      </div>
    </>
  );
}
