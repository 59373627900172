import React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}
export default function SectionHeading({ className, children }: Props) {
  return (
    <h3 className={`text-2xl font-bold md:text-3xl ${className || ''}`}>
      {children}
    </h3>
  );
}
