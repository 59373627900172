'use client';
import React from 'react';
import { useTranslations } from 'next-intl';
import AfiwaTag from '@/ui/shared/components/afiwaTag';
import SectionHeading from '@/ui/shared/components/sectionHeading';
import AfiwaButton from '@/ui/shared/components/afiwaButton';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import ExchangeUser from '@/ui/shared/components/animations/exchangeUser';
import appRoutes from '@/constants/routes';

function JoinAffiliateProgram() {
  const heroText = useTranslations('homepage.join_affiliate_program');

  return (
    <div className="flex flex-col items-center  md:flex-row">
      <div className="mb-3 ml-0 flex-1  text-center md:mb-0 md:ml-16 md:text-left">
        <AfiwaTag text={heroText('affiliate')} />
        <SectionHeading className="my-10">
          <span className="mr-1 text-primary-dark">{heroText('join_our')}</span>
          <span className="mr-1 inline-block text-primary">
            {heroText('program')}
          </span>
          <br />
          <span className="text-primary">{heroText('affiliation')}</span>
        </SectionHeading>
        <p className="w-full font-light md:w-[400px] lg:w-[500px]">
          {heroText('afiwa_description')}
        </p>
        <AfiwaButton
          type="primary"
          text={heroText('become_affiliate')}
          wrapperClass="mt-10"
          rightIcon={<ArrowRightIcon height={15} width={15} />}
          href={appRoutes.affiliate_program}
        />
      </div>
      <div className="flex flex-1 items-center justify-start md:justify-center">
        <ExchangeUser />
      </div>
    </div>
  );
}

export default JoinAffiliateProgram;
