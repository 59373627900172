import React from 'react';

export const afiwaPhone: string = '+7 (989) 577-21-36';
export const afiwaEmail: string = 'info@afiwaexchange.com';

export const appName = 'AFIWA EXCHANGE';

type Socials = {
  [key: string]: { icon: React.ReactNode; link: string };
};

export const afiwaSocials: Socials = {
  whatsapp: {
    icon: '/socialMedia/whatsapp.svg',
    link: 'https://wa.me/79895772136',
  },
  telegram: {
    icon: '/socialMedia/telegram.svg',
    link: 'https://t.me/afiwa_exchange_support',
  },
  facebook: {
    icon: '/socialMedia/facebook.svg',
    link: 'https://www.facebook.com/profile.php?id=61560352671807',
  },

  youtube: {
    icon: '/socialMedia/youtube.svg',
    link: 'https://www.youtube.com/channel/UCh8M72vvIiiChEws9pfeO2A',
  },
  instagram: {
    icon: '/socialMedia/instagram.svg',
    link: 'https://www.instagram.com/afiwa_ex/',
  },
  tiktok: {
    icon: '/socialMedia/tiktok.svg',
    link: 'https://www.tiktok.com/@afiwaexchange',
  },
};

export enum USER_ROLE {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum PAYMENT_TYPE {
  MOBILE = 'MOBILE',
  CRYPTO = 'CRYPTO',
  BANK = 'BANK',
}

export enum TRADE_TYPE {
  FIAT = 'FIAT',
  CRYPTO = 'CRYPTO',
}

export enum TRANSACTION_STATUS {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
}
